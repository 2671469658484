import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import SpreadPrice from "../utils/SpreadPrice";
import { useMemo } from "react";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import useModal from "../hooks/useModal";
import SentBadge from "../deliverables/SentBadge";
import getInvoiceDetails from "../invoices/getInvoiceDetails";

const ProductivityList = ({ productivity }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "start_time",
        header: "Date",
        cell: (info) => dayjs(info.getValue()).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "user",
        header: "User",
        cell: (info) => (
          <a href={`mailto:${info.row.original.user_email}`}>
            {info.getValue()}
          </a>
        ),
      },
      {
        accessorKey: "job_full_name",
        header: "Job",
        cell: (info) => (
          <Link to={info.row.original.job_link}>{info.getValue()}</Link>
        ),
      },
      {
        accessorKey: "time_in_hours",
        header: "Hours",
      },
      {
        accessorKey: "chargeout_amount",
        header: "Charge Out",
        cell: (info) => <SpreadPrice price={info.getValue()} />,
      },
      {
        accessorKey: "deliverables",
        header: "Deliverables",
        cell: (info) => {
          const { modal, toggle } = useModal();

          return (
            <>
              <Button
                onClick={toggle}
                color="link"
                className="p-0 text-nowrap text-secondary"
              >
                {info.getValue().length} Deliverable
                {info.getValue().length !== 1 ? "s" : ""} added
              </Button>
              <Modal
                className="wd-md-1000 mx-wd-800 w-95"
                isOpen={modal}
                toggle={toggle}
              >
                <ModalHeader>Deliverables</ModalHeader>
                <ModalBody>
                  <div className="space-y-3">
                    {info.getValue().map((deliverable) => {
                      const invoicedAt = getInvoiceDetails(
                        info.row.original.invoice,
                      );
                      return (
                        <div className="bg-white rounded-lg shadow-sm border p-3">
                          <div>
                            <Link
                              className="text-dark d-block"
                              to={deliverable.link}
                            >
                              {deliverable.name}
                            </Link>
                            <Link
                              className="text-secondary"
                              to={deliverable.job.link}
                            >
                              {deliverable.job.name}
                            </Link>
                          </div>
                          <div className="d-flex space-x-3 mt-3">
                            <SentBadge
                              className="rounded-pill shadow"
                              sent={deliverable.sent}
                            />
                            <Badge
                              className="rounded-pill shadow"
                              color={invoicedAt.color}
                            >
                              {invoicedAt.link}
                            </Badge>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={toggle}>Close</Button>
                </ModalFooter>
              </Modal>
            </>
          );
        },
      },
      {
        accessorKey: "comments",
        header: "Notes",
        cell: (info) => {
          const { modal, toggle } = useModal();

          if (!info.getValue()) {
            return '-';
          }

          return (
            <>
              <i style={{ cursor: "pointer" }} onClick={toggle} class="fa fa-sticky-note" aria-hidden="true" title="Notes"></i>
              <Modal
                className="wd-md-1000 mx-wd-800 w-95"
                isOpen={modal}
                toggle={toggle}
              >
                <ModalHeader>Notes</ModalHeader>
                <ModalBody>
                  {info.getValue()}
                </ModalBody>
                <ModalFooter>
                  <Button onClick={toggle}>Close</Button>
                </ModalFooter>
              </Modal>
            </>
          );
        },
      },
    ],
    [productivity],
  );

  return <ReactTable columns={columns} data={productivity} enableCsvExport />;
};

export default ProductivityList;
